//
//
//
//
//



export default {
   components: {
  
  },
  name: 'GoodsScan',
}
